:root {
	--font-family: Arial, sans-serif;
	--font-family-bold: Arial, sans-serif;
	// default font-sizes are defined in _common-typography.scss. Override the default font sizes here.
	--font-weight-bold-xl10: 700;
	--font-weight-bold-xl9: 700;
	--font-weight-bold-xl8: 700;
	--font-weight-bold-xl7: 700;
	--font-weight-bold-xl6: 700;
	--font-weight-bold-xl5: 700;
	--font-weight-bold-xl4: 700;
	--font-weight-bold-xl3: 700;
	--font-weight-bold-xl2: 700;
	--font-weight-bold-xl: 700;
	--font-weight-bold-large: 700;
	--font-weight-bold-regular: 700;
	--font-weight-bold-small: 700;
	--font-weight-bold-xsmall: 700;
	--font-weight-bold-xxsmall: 700;
}
